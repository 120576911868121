<template>
  <div class="card" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Bill Number</th>
            <th>Batch No</th>
            <th>Production Costing Name</th>
            <th>Product</th>
            <th>Customer</th>
            <th>Production Quantity</th>
            <th class="text-right">Rate</th>
            <th class="text-right">FG Value</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in productCosting" :key="i">
            <td>{{ item.date }}</td>
            <td>{{ item.bill_number }}</td>
            <td>{{ item.batch_no }}</td>
            <td>{{ item.product_costing_name }}</td>
            <td>{{ item.product_name }}</td>
            <td>{{ item.customer_name }}</td>
            <td>{{ item.production_quantity }}</td>
            <td class="text-right">{{ commaFormat(item.rate) }}</td>
            <td class="text-right">{{ commaFormat(item.finished_goods_value) }}</td>
            <td>
              <button class="btn btn-primary btn-sm" @click="goToEdit(item.id)" style="cursor: pointer">
                <i class="fas fa-eye"></i>
              </button>
              <button class="btn btn-secondary btn-sm" @click="goToPrint(item.id)" style="cursor: pointer; margin-left: 10px">
                <i class="fas fa-print"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="!productCosting.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import figureFormatter from '@/services/utils/figureFormatter';
import {useRoute, useRouter} from "vue-router";

const {commaFormat} = figureFormatter()
const props = defineProps({
  productCosting: Array
})

const route = useRoute()
const router = useRouter()
const goToEdit = (id) => {
  //TODO::need next PR
}
const goToPrint = (id) => {
  //TODO::need next PR
}
</script>
